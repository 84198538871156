.inventory-list {
  width: 100%;

  h2 {
    text-align: center;
    text-transform: uppercase;
  }
}

.inventory-list-items {
  display: grid;
  grid-gap: .5rem;
  grid-template-columns: repeat( auto-fill, minmax(125px, 1fr) );
}

.inventory-item-carousel {
  text-align: center;
}

.guide-inventory-item-button {
  background-color: #fff;
  border-color: #000;
  color: #000;
  text-align: center;
  padding: 10px;
}

.guide-inventory-item-image {
  width: 100%;
}
