.ruins,
.ruins-forbidden-treasure {
  & .player-multi-row-inventory-container {
    width: 100%;
    min-height: 250px;
    padding: 10px;
    background: #E4E4E4;
    border: 5px solid #A37BBA;
    text-align: center;
  }
  & .player-inventory-lock-container {
    border: solid 3px #F7C657;
    height: 260px;
    background: url('../../../images/ruins_inventory_background.png');
  }
  & .player-multi-row-inventory-title {
    font-size: 25px;
  }
  & .player-inventory-lock-title {
    padding: 10px 15px;
    color: #102C10;
    display: inline-block;
    background-color: #F7C657;
  }
  & .slick-list {
    margin-left: 40px;
    margin-right: 40px;
  }
  & .player-inventory-item-carousel {
    height: 300px;
  }
  & .player-multi-row-inventory-item-button {
    background-color: transparent;
    border-color: transparent;
    color: #1a431a;
    & :hover {
      background-color: #1a431a;
      color: #f7c657;
      border: #1a431a;
    }
  }
  & .player-inventory-item-image {
    margin: 0 auto;
    width: 100px;
    max-height: 120px;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton {
    position: absolute;
    bottom: 200px;
    right: 0px;
    background-color: transparent;
    border: none;
  }
  & .nextImage {
    width: 20px;
  }
  & .prevButton {
    position: absolute;
    bottom: 200px;
    left: 0px;
    background-color: transparent;
    border: none;
  }
  & .prevImage {
    width: 20px;
  }
}

.gold-rush {
  & .player-multi-row-inventory-container {
    min-height: 250px;
    padding: 5px;
    background: #A07E2D;
    background-size: cover;
    text-align: center;
    border: solid 5px #754C24;

  }
  & .player-inventory-lock-container {
    border: solid 3px #F7C657;
    height: 270px;
  }
  & .player-inventory-title {
    font-size: 25px;
    margin-top: 20px;
  }
  & .player-inventory-lock-title {
    padding: 10px 15px;
    color: #102C10;
    display: inline-block;
    background-color: #F7C657;
  }
  & .player-multi-row-inventory-item-button {
    background-color: transparent;
    border-color: transparent;
    color: #f7c657;
    & :hover {
      background-color: #1a431a;
      color: #f7c657;
      border: #1a431a;
    }
  }
  & .player-inventory-item-image {
    margin: 0 auto;
    width: 100px;
    max-height: 120px;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: relative;
    left: 50px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: relative;
    right: 50px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton {
    position: absolute;
    bottom: 200px;
    right: 0px;
    background-color: transparent;
    border: none;
  }
  & .nextImage {
    width: 20px;
  }
  & .prevButton {
    position: absolute;
    bottom: 200px;
    left: 0px;
    background-color: transparent;
    border: none;
  }
  & .prevImage {
    width: 20px;
  }
}

.the-heist {
  & .player-multi-row-inventory-container {
    width: 100%;
    min-height: 250px;
    padding: 0px 50px 0px 50px;
    background-size: 100% 350px;
    text-align: center;
    margin-bottom: 80px;
    border: solid 3px  #02a6ff;
  }
  & .player-inventory-lock-container {
    border: solid 3px  #02a6ff;
    height: 300px;
  }
  & .player-multi-row-inventory-title {
    font-size: 25px;
  }
  & .player-inventory-lock-title {
    padding: 10px 15px;
    color: #102C10;
    display: inline-block;
    background-color:  #02a6ff;
  }
  & .player-inventory-title {
    position: relative;
    top: 50px;
  }
  & .slick-list {
    margin-left: 40px;
    margin-right: 40px;
  }
  & .player-inventory-item-carousel {
    height: 300px;
    position: relative;
    top: 10px;
    padding-top: 30px;
  }
  & .player-multi-row-inventory-item-button {
    width: 200px;
    background-color: transparent;
    border-color: transparent;
    color: #02a6ff;
    & :hover {
      background-color: #02a6ff;
      color: #102044;
      border: #02a6ff;
    }
  }
  & .player-inventory-item-image {
    width: 100px;
    max-height: 120px;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: relative;
    left: 50px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: absolute;
    right: 100px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton {
    position: absolute;
    bottom: 200px;
    right: 0px;
    background-color: transparent;
    border: none;
  }
  & .nextImage {
    width: 20px;
  }
  & .prevButton {
    position: absolute;
    bottom: 200px;
    left: 0px;
    background-color: transparent;
    border: none;
  }
  & .prevImage {
    width: 20px;
  }
}

.prison-break {
  width: 100%;
  & .player-multi-row-inventory-container {
    width: 100%;
    min-height: 250px;
    padding: 0px 50px 0px 50px;
    background: #E0D1B2;
    text-align: center;
    border: 5px solid #A67C52;
    margin-bottom: 80px;
    margin-top: 100px;
  }
  & .player-inventory-lock-container {
    border: solid 3px #A57D53;
    height: 300px;
  }
  & .player-multi-row-inventory-title {
    font-size: 25px;
  }
  & .player-inventory-lock-title {
    padding: 10px 15px;
    color: #102C10;
    display: inline-block;
    background-color: #A57D53;
  }
  & .player-inventory-title {
    position: relative;
    top: 50px;
  }
  & .slick-list {
    margin-left: 40px;
    margin-right: 40px;
  }

  & .player-inventory-item-carousel {
    height: 300px;
    position: relative;
    top: 10px;
    padding-top: 30px;
  }
  & .player-multi-row-inventory-item-button {
    width: 200px;
    background-color: transparent;
    border-color: transparent;
    color: #4A2B2F;
    & :hover {
      background-color: #A57D53;
      color: white;
      border: #A57D53;
    }
  }
  & .player-inventory-item-image {
    width: 100px;
    max-height: 120px;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: relative;
    left: 50px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: absolute;
    right: 100px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton {
    position: absolute;
    bottom: 200px;
    right: 0px;
    background-color: transparent;
    border: none;
  }
  & .nextImage {
    width: 20px;
  }
  & .prevButton {
    position: absolute;
    bottom: 200px;
    left: 0px;
    background-color: transparent;
    border: none;
  }
  & .prevImage {
    width: 20px;
  }
}


.truth-seekers {
  width: 100%;
  & .player-multi-row-inventory-container {
    width: 100%;
    min-height: 250px;
    padding: 0px 50px 0px 50px;
    background: #E4C6A2;
    text-align: center;
    border: 5px solid #A67C52;
    margin-bottom: 80px;
  }
  & .player-inventory-lock-container {
     height: 340px;
  }
  & .player-multi-row-inventory-title {
    font-size: 25px;
  }
  & .player-inventory-lock-title {

  }
  & .player-inventory-title {
    position: relative;
    top: 50px;
  }
  & .slick-list {
    margin-left: 40px;
    margin-right: 40px;
  }

  & .player-inventory-item-carousel {
    height: 300px;
    position: relative;
    top: 70px;
    padding-top: 30px;
  }
  & .player-multi-row-inventory-item-button {
    width: 200px;
    background-color: transparent;
    border-color: transparent;
    color: #4A2B2F;
    & :hover {
      background-color: #A57D53;
      color: white;
      border: #A57D53;
    }
  }
  & .player-inventory-item-image {
    width: 100px;
    max-height: 120px;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: relative;
    left: 50px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: absolute;
    right: 100px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton {
    position: absolute;
    bottom: 200px;
    right: 8px;
    background-color: transparent;
    border: none;
  }
  & .nextImage {
    width: 20px;
  }
  & .prevButton {
    position: absolute;
    bottom: 200px;
    left: 0px;
    background-color: transparent;
    border: none;
  }
  & .prevImage {
    width: 20px;
  }
}

.proctor-and-gamble {
  width: 100%;
  & .player-multi-row-inventory-container {
    width: 100%;
    background-size: 100% 350px;
    text-align: center;
    border: solid 10px #E97623;
    margin-top: 20px;
  }
  & .slider-body {
    background: white;
    opacity: 0.8;
  }
  & .player-inventory-lock-container {
    border: solid 10px #F277C7;
    margin-top: 12px;
  }
  & .player-multi-row-inventory-title {
    font-size: 25px;
    padding: 10px 15px;
    color: white;
    background-color: #E97623;
    text-align: center;
    text-transform: uppercase;
  }
}



