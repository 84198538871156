.media-module {
  text-align: center;
  background-color: #000;
  color: #fff;
  padding: 2rem .5rem;
}

.guide-media-buttons {
  margin: 10px;
  border-radius: 20px;
  background-color: #f7c657;
  color: #1a431a;
  border: none;
  & :hover {
    color: #f7c657;
    background-color: #1a431a;
  }
  padding: .25rem 1rem;
}

.center {
  text-align: center;
}
