.signin-section {
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  .signin-card {
    display: flex;
    flex-direction: column;
    width: 400px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    box-sizing: border-box;
    color: rgb(94, 108, 132);
    margin: 0px auto 24px;
    padding: 32px 40px;
    background: rgb(255, 255, 255);
    border-radius: 3px;
    .heading {
      text-align: center;
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .sub-heading {
      text-align: center;
      font-size: 13px;
      margin-top: -10px;
      margin-bottom: 10px;
    }
    .message {
      margin-top: 10px;
    }
    form {
      mat-form-field {
        width: 100%;
      }
    }
    button {
      width: 100%;
    }
  }
  .xola-logo {
    width: 80px;
    margin: auto;
    margin-bottom: 20px;
  }
  .xola-linked {
    background-color: #46ca53;
    text-align: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .xola-unlink-btn {
    width: 140px !important;
    margin-top: 0.5rem;
    padding: 2px;
    font-size: 14px;
    text-align: center;
  }
}

.settings-tabs-container {
  .navbar {
    padding-top: 3px;
    padding-bottom: 0px; 
  }
  .nav-link {
    font-size: 0.8rem;
    font-weight: 500;
    &.active {
      background: none;
      border-bottom: #28a2b8 solid 7px;
      border-radius: 0;
      font-weight: 600;
    }
  }
}
