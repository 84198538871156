.signin-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .signin-card {
    display: flex;
    flex-direction: column;
    width: 400px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    box-sizing: border-box;
    color: rgb(94, 108, 132);
    margin: 0px auto 24px;
    padding: 32px 40px;
    background: rgb(255, 255, 255);
    border-radius: 3px;
    .heading {
      text-align: center;
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .message {
      margin-top: 10px;
    }
    form {
      mat-form-field {
        width: 100%;
      }
    }
    button {
      width: 100%;
    }
  }
}
