@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"), url("../../../../fonts/Montserrat-Bold.ttf");
}
.proctor-and-gamble {
  & .player-wrapper {
    padding: 25px;
    background: url("../../../../images/Remote_P&G_Dashboard_Background.png");
    background-size: 100% 100%;
    margin: 0px;
    width: 100%;
    color: #062b1d;
  }
  & .player-inventory-container {
    font-family: "MontserratBold";
  }
  & .player-inventory-lock-container {
    font-family: "MontserratBold";
  }
  & .player-multi-row-inventory-container {
    font-family: "MontserratBold";
  }
  & .watch-trailer {
    background-color: #E4FF04;
    color: #062A4D;
    font-style: normal !important;
    border-radius: 20px;
    border-color: #E4FF04;
    font-weight: 900;
    margin-top: 20px;
    text-transform: uppercase;
    padding: 12px 24px;
  }
  & .coming-soon {
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    color: white;
  }
  & .player-logo-section-sm {
    display: none;
    text-align: center;
    vertical-align: middle;
  }
  & .player-logo-section-lg {
    display: block;
  }
  & .player-logo-section {
    & .logo-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom:10px;
      & .player-logos-1 {
        max-width: 280px;
        padding: 5px;
        height: 45px;
        margin-right: 5px;
      }
      & .player-logos-2 {
        max-width: 145px;
        padding: 5px;
        margin-top: -27px;
        margin-right: 5px;
      }
      & .player-logos-3 {
        width:155px;
        padding: 5px;
      }
    }
  }
  & .aux-image {
    display: none;
  }
  & .my-50 {
    margin-top: 1px;
  }

  & .no-show {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .truth-seeker-coming-soon-mobile {
      margin: auto;
      text-align: center;
      display: block !important;
      margin-bottom: 27px;
    }
     .player-logo-section {
      & .logo-container {
        display: flex;
        margin-bottom:10px;
        justify-content: center;
        & .player-logos-1 {
          max-width: 200px;
          padding: 5px;
          height: 35px;
          margin-right: 5px;
        }
        & .player-logos-2 {
          max-width: 120px;
          padding: 5px;
          margin-top: -27px;
          margin-right: 5px;
        }
        & .player-logos-3 {
          width:130px;
          padding: 5px;
          display: block;
          text-align: center;
        }
      }
    }
  }
}
