.ruins,
.ruins-forbidden-treasure {
  width: 100%;
  & .player-inventory-container {
    width: 100%;
    height: 230px;
    padding: 10px;
    background: url('../../../images/ruins_inventory_background.png');
    background-size: cover;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 1px #000 inset;
  }
  & .player-inventory-lock-container {
    border: solid 3px #F7C657;
    height: 250px;
    background: url('../../../images/ruins_inventory_background.png');
  }
  & .player-inventory-title {
    font-size: 25px;
  }
  & .player-inventory-lock-title {
    padding: 10px 15px;
    color: #102C10;
    display: inline-block;
    background-color: #F7C657;
  }
  & .slick-list {
    margin-left: 40px;
    margin-right: 40px;
  }
  & .player-inventory-item-carousel-lock {
    height: 300px;
    position: relative;
    top: -3px;
    padding-top: 20px;
  }
  & .player-inventory-item-carousel {
    height: 300px;
  }
  & .player-inventory-item-button {
    width: 100%;
    background-color: transparent;
    border-color: transparent;
    color: #1a431a;
    & :hover {
      background-color: #1a431a;
      color: #f7c657;
      border: #1a431a;
    }
  }
  & .player-inventory-item-image {
    margin: auto;
    width: 100px;
    max-height: 120px;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton {
    position: absolute;
    bottom: 200px;
    right: 0px;
    background-color: transparent;
    border: none;
  }
  & .nextImage {
    width: 20px;
  }
  & .prevButton {
    position: absolute;
    bottom: 200px;
    left: 0px;
    background-color: transparent;
    border: none;
  }
  & .prevImage {
    width: 20px;
  }
}

.gold-rush {
  width: 100%;
  & .player-inventory-container {
    width: 100%;
    height: 400px;
    padding: 10px;
    background: url('../../../images/gr_inventory_background.png');
    background-size: cover;
    text-align: center;
    border: none;
  }
  & .player-inventory-lock-container {
    border: solid 3px #F7C657;
    height: 270px;
  }
  & .player-inventory-title {
    font-size: 25px;
    margin-top: 50px;
  }
  & .player-inventory-lock-title {
    padding: 10px 15px;
    color: #102C10;
    display: inline-block;
    background-color: #F7C657;
  }
  & .slick-list {
    margin-left: 40px;
    margin-right: 40px;
  }
  & .player-inventory-item-carousel-lock {
    height: 300px;
    position: relative;
    top: 0px;
    padding-top: 30px;
  }
  & .player-inventory-item-carousel {
    height: 300px;
    position: relative;
    top: 10px;
    padding: 10px;
  }
  & .player-inventory-item-button {
    width: 100%;
    background-color: transparent;
    border-color: transparent;
    color: #f7c657;
    & :hover {
      background-color: #1a431a;
      color: #f7c657;
      border: #1a431a;
    }
  }
  & .player-inventory-item-image {
    margin: 0 auto;
    width: 100px;
    max-height: 120px;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: relative;
    left: 50px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: relative;
    right: 50px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton {
    position: absolute;
    bottom: 200px;
    right: 0px;
    background-color: transparent;
    border: none;
  }
  & .nextImage {
    width: 20px;
  }
  & .prevButton {
    position: absolute;
    bottom: 200px;
    left: 0px;
    background-color: transparent;
    border: none;
  }
  & .prevImage {
    width: 20px;
  }
}

.the-heist {
  width: 100%;
  & .player-inventory-container {
    width: 100%;
    height: 350px;
    padding: 0px 50px 0px 50px;
    background: url('../../../images/heist_inventory_background.png');
    background-size: 100% 350px;
    text-align: center;
    border: none;
    margin-bottom: 80px;
  }
  & .player-inventory-lock-container {
    border: solid 3px  #02a6ff;
    height: 300px;
  }
  & .player-inventory-title {
    font-size: 25px;
  }
  & .player-inventory-lock-title {
    padding: 10px 15px;
    color: #102C10;
    display: inline-block;
    background-color:  #02a6ff;
  }
  & .player-inventory-title {
    position: relative;
    top: 50px;
  }
  & .slick-list {
    margin-left: 40px;
    margin-right: 40px;
  }
  & .player-inventory-item-carousel-lock {
    height: 300px;
    position: relative;
    top: 2px;
    padding-top: 30px;
  }
  & .player-inventory-item-carousel {
    height: 300px;
    position: relative;
    top: 20px;
    padding-top: 30px;
  }
  & .player-inventory-item-button {
    width: 200px;
    background-color: transparent;
    border-color: transparent;
    color: #02a6ff;
    & :hover {
      background-color: #02a6ff;
      color: #102044;
      border: #02a6ff;
    }
  }
  & .player-inventory-item-image {
    width: 100px;
    max-height: 120px;
    margin: auto;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: relative;
    left: 50px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: absolute;
    right: 100px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton {
    position: absolute;
    bottom: 200px;
    right: 0px;
    background-color: transparent;
    border: none;
  }
  & .nextImage {
    width: 20px;
  }
  & .prevButton {
    position: absolute;
    bottom: 200px;
    left: 0px;
    background-color: transparent;
    border: none;
  }
  & .prevImage {
    width: 20px;
  }
}

.prison-break {
  width: 100%;
  & .player-inventory-container {
    width: 100%;
    height: 350px;
    padding: 0px 50px 0px 50px;
    background: url('../../../images/pb_inventory_background.png');
    background-size: 100% 350px;
    text-align: center;
    border: none;
    margin-bottom: 80px;
    margin-top: 100px;
  }
  & .player-inventory-lock-container {
    border: solid 3px #A57D53;
    height: 300px;
    background-color: #F6E5C7;
  }
  & .player-inventory-title {
    font-size: 25px;
  }
  & .player-inventory-lock-title {
    padding: 10px 15px;
    color: #102C10;
    display: inline-block;
    background-color: #A57D53;
  }
  & .player-inventory-title {
    position: relative;
    top: 50px;
  }
  & .slick-list {
    margin-left: 40px;
    margin-right: 40px;
  }
  & .player-inventory-item-carousel-lock {
    height: 300px;
    position: relative;
    top: 2px;
    padding-top: 30px;
  }
  & .player-inventory-item-carousel {
    height: 300px;
    position: relative;
    top: 10px;
    padding-top: 30px;
  }
  & .player-inventory-item-button {
    width: 200px;
    background-color: transparent;
    border-color: transparent;
    color: #4A2B2F;
    & :hover {
      background-color: #A57D53;
      color: white;
      border: #A57D53;
    }
  }
  & .player-inventory-item-image {
    width: 100px;
    max-height: 120px;
    margin: auto;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: relative;
    left: 50px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: absolute;
    right: 100px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton {
    position: absolute;
    bottom: 200px;
    right: 0px;
    background-color: transparent;
    border: none;
  }
  & .nextImage {
    width: 20px;
  }
  & .prevButton {
    position: absolute;
    bottom: 200px;
    left: 0px;
    background-color: transparent;
    border: none;
  }
  & .prevImage {
    width: 20px;
  }
}

.truth-seekers {
  width: 100%;
  & .player-inventory-container {
    width: 100%;
    height: 350px;
    padding: 0px 50px 0px 50px;
    background: url('../../../images/TruthSeekers_Dashboard_F copy_InventoryModule2.png');
    background-size: 100% 350px;
    text-align: center;
    border: none;
    margin-top: -20px;
  }
  & .player-inventory-lock-container {
    height: 340px;
    background: url('../../../images/TruthSeekers_Dashboard_F copy_LockModule2.png');
    background-size: 100% 100%;
    border: none;
    margin-top: 12px;
  }
  & .player-inventory-title {
    font-size: 25px;
  }
  & .player-inventory-lock-title {
  }
  & .player-inventory-title {
    position: relative;
    top: 50px;
  }
  & .slick-list {
    margin-left: 40px;
    margin-right: 40px;
  }

  & .player-inventory-item-carousel {
    height: 300px;
    position: relative;
    top: 70px;
    padding-top: 30px;
  }
  & .player-inventory-item-carousel-lock {
    height: 300px;
    position: relative;
    top: 50px;
    padding-top: 30px;
  }
  & .player-inventory-item-button {
    width: 200px;
    background-color: transparent;
    border-color: transparent;
    color: #4A2B2F;
    & :hover {
      background-color: #A57D53;
      color: white;
      border: #A57D53;
    }
  }
  & .player-inventory-item-image {
    width: 100px;
    max-height: 120px;
    margin: auto;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: relative;
    left: 50px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: absolute;
    right: 100px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton {
    position: absolute;
    bottom: 200px;
    right: 8px;
    background-color: transparent;
    border: none;
  }
  & .nextImage {
    width: 20px;
  }
  & .prevButton {
    position: absolute;
    bottom: 200px;
    left: 0px;
    background-color: transparent;
    border: none;
  }
  & .prevImage {
    width: 20px;
  }
}


.proctor-and-gamble {
  width: 100%;
  & .player-inventory-container {
    width: 100%;
    background-size: 100% 350px;
    text-align: center;
    border: solid 10px #E97623;
    margin-top: 20px;
  }
  & .slider-body {
    background: white;
    opacity: 0.8;
  }
  & .player-inventory-lock-container {
    border: solid 10px #F277C7;
    margin-top: 12px;
  }
  & .player-inventory-title {
    font-size: 25px;
    padding: 10px 15px;
    color: white;
    background-color: #E97623;
    text-align: center;
    text-transform: uppercase;
  }
  & .player-inventory-lock-title {
    padding: 10px 15px;
    color: white;
    background-color: #F277C7;
    text-align: center;
  }
  & .slick-list {
    margin-left: 40px;
    margin-right: 40px;
  }

  & .player-inventory-item-carousel {
    height: 260px;
    position: relative;
    top: 20px;
    padding-top: 30px;
  }
  & .player-inventory-item-carousel-lock {
    height: 260px;
    position: relative;
    top: 20px;
    padding-top: 30px;
  }
  & .player-inventory-item-button {
    width: 200px;
    background-color: transparent;
    border-color: transparent;
    color: #4A2B2F;
    & :hover {
      background-color: #A57D53;
      color: white;
      border: #A57D53;
    }
  }
  & .player-inventory-item-image {
    width: 100px;
    max-height: 120px;
    margin: auto;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: relative;
    left: 50px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
    position: absolute;
    right: 100px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton {
    position: absolute;
    bottom: 130px;
    right: 8px;
    background-color: transparent;
    border: none;
  }
  & .nextImage {
    width: 20px;
  }
  & .prevButton {
    position: absolute;
    bottom: 130px;
    left: 0px;
    background-color: transparent;
    border: none;
  }
  & .prevImage {
    width: 20px;
  }
}

.depths {
  $depths-green: #062b1d;
  $depths-teal1: #b2e0de;
  $depths-teal2: #00e5df;
  $depths-teal3: #09c9ce;
  $depths-teal4: #139ba4;
  $depths-orange: #d68628;
  $depths-white: #fff;
  $inventory-container-height: 290px;
  width: 100%;
  & .player-inventory-container {
    width: 100%;
    height: $inventory-container-height;
    background: url('../../../images/depths_inventory_background.png');
    background-size: 100% 100%;
    text-align: center;
    border-radius: 8px;
    position: relative;
  }
  & .player-inventory-lock-container {
    height: $inventory-container-height;
    background: url('../../../images/depths_locks_background.png');
    background-size: 100% 100%;
    border-radius: 8px;
    position: relative;
  }
  & .player-inventory-label {
    position: absolute;
    bottom: 36.75px;
    left: 49.35%;
    transform: translateX(-50%);
    width: 150px;
  }
  & .player-inventory-title {
    position: absolute;
    bottom: 39px;
    left: 49.35%;
    transform: translateX(-50%);
    text-transform: uppercase;
  }
  & .player-inventory-lock-title {
    color: #102C10;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 39px;
    text-transform: uppercase;
  }
  & .slick-list {
    margin-left: 70px;
    margin-right: 70px;
  }
  & .slick-track {
    margin-top: 40px;
  }
  & .player-inventory-item-carousel-lock {
    height: 300px;
    position: relative;
    top: -3px;
    padding-top: 20px;
  }
  & .player-inventory-item-carousel {
    height: 300px;
  }
  & .player-inventory-item-button {
    width: 100%;
    background-color: transparent;
    border-color: transparent;
    border: 0;
    color: $depths-green;
    & img {
      transition: transform .2s;
    }
    &:hover img {
      transform: scale(1.05);
    }
    &:focus {
      box-shadow: unset;
    }
    &:active {
      background-color: transparent !important;
      box-shadow: unset;
      border: unset;
      color: unset !important;
    }
    &:focus:active {
      background-color: transparent !important;
      box-shadow: unset !important;
    }
  }
  & .player-inventory-item-image {
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 80px;
    max-height: 120px;
    object-fit: contain;
  }
  & .player-arrows-prev-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
  }
  & .player-arrows-prev-image {
    width: 100%;
    height: 100%;
  }
  & .player-arrows-next-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0px;
  }
  & .player-arrows-next-image {
    width: 100%;
    height: 100%;
  }
  & .nextButton, & .prevButton {
    position: absolute;
    background-color: transparent;
    border: none;
    top: 85px;
    transition: opacity .2s;
    &:hover {
      opacity: .8;
    }
    &:focus {
      box-shadow: unset;
    }
    &:active {
      background-color: transparent !important;
      box-shadow: unset;
    }
    &:focus:active {
      background-color: transparent !important;
      box-shadow: unset !important;
    }
    & img {
      width: 20px;
    }
  }
  & .player-inventory-lock-container .nextButton {
    right: 5%;
  }
  & .player-inventory-container .nextButton {
    right: 2.5%;
  }
  & .player-inventory-lock-container .prevButton {
    left: 5%;
  }
  & .player-inventory-container .prevButton {
    left: 2.5%;
  }
}

