.sidebar-custom {
    position: fixed;
    width: 33vw;
    bottom: 0;
    right: 0;
    z-index: 100;
    padding: 10px 0 0 10px;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    background-color: #eee;
    overflow-y: scroll;
    transition: transform .4s ease;
}
@media (max-width: 576px) {
    .sidebar-custom {
        width: 83vw;
    }
    .panel-last-btn {
        margin-top: 15px;
    }
}
@media (min-width: 769px) and (max-width: 1120px) {
    .panel-last-btn {
        margin-top: 15px;
    }
}
@media (min-width: 577px) and (max-width: 768px) {
    .sidebar-custom {
        width: 63vw;
    }
}
.top-fix {
    top: 0;
}
.top-mar {
    top: 68px;
}
.cancel-btn {
    cursor: pointer;
}
.heading {
    font-weight: 800;
    font-size: 25px;
}
.panel-row {
    margin: 10px 0 10px 7px;

    .panel-col {
        padding: 0;
        .text-label {
            font-weight: 800;
            font-size: 16px;
        }
    }
}

.password-popover {
    min-width: 350px;
    .info-heading {
        font-weight: 800;
        margin-bottom: 5px;
    }
    .form-control {
        padding: 0.2rem 0.5rem;
        font-size: 0.7rem;
    }
    .form-control:disabled, .form-control[readonly] {
        background-color: #fdfdfd;
        opacity: 1;
    }
    .btn {
        padding: 0.2rem 0.5rem;
        font-size: 0.7rem;
    }
    .col-md-8 {
        padding-right: 0;
    }
    .copy-btn {
        font-size: 0.7rem;
        padding: 0.4rem 0.1rem;
    }
}
.panel-col-inner {
   padding-left: 0;
}
.game-state-btn {
    border-radius: 20px;
    background-color: #000;
    color: #fff;
    border: none;
    min-width: 90px;
}

.game-state-btn-selected {
    border-radius: 20px;
    background-color: #fff !important;
    color: #000 !important;
    border: none;
    min-width: 90px;
}

.cancel-form-btn {
    border-radius: 20px;
    background-color: #fff;
    color: #000;
    border: none;
    margin-right: 15px;
    min-width: 90px;
}

.form-section {
    margin-top: 20px;
    color: #000;
    .meeting-col {
        padding: 0;
    }
    .meeting-btn {
        background-color: #000;
        color: #fff;
        padding: .05rem .75rem;
    }
    .meeting-btn-selected {
        background-color: #fff;
        color: #000;
        padding: .05rem .75rem;
    }
    .meeting-label {
        padding: 0;
        margin-bottom: 10px;
        font-weight: 800;
        font-size: 0.9rem;
    }
    .heading-label {
        padding: 0;
        margin-bottom: 20px;
        font-weight: 800;
        font-size: 1.2rem;
    }
    .row-mar {
        margin-bottom: 20px;
    }
    .checkbox-row {
        font-weight: 800;
        color: #000;
        font-size: 0.9rem;
    }
    .select-label {
        font-weight: 800;
        font-size: 0.8rem;
        margin-bottom: 5px;
    }
    .required-label {
        font-weight: 500;
        font-size: 0.8rem;
        color: red
    }
    .select-row {
        margin-bottom: 10px;
    }
    .last-row {
        margin-bottom: 3em;
    }
    .colon {
        padding-top: 6px;
        padding-right: 0px
    }
    .time-col {
        padding-right: 0px;
    }
    .search-icon {
        padding: 11px 10px 0px 10px;
        background-color: #fff;
        border: 1px solid #ced4da;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
    .col-right {
        padding-right: 0px;
    }
    .col-left {
        padding-left: 0px;
    }
}

.btn-row {
    margin-bottom: 10px;
}

.form-file-label {
    font-weight: 800;
    font-size: 0.8rem;
}
.during-btn {
    background-color: #fff;
    font-size: 1rem;
    font-weight: 500;
}
.during-counter {
    border-radius: 20px;
    background-color: #000;
    color: #fff;
    border: none;
    width: 60px;
    justify-content: center;
    padding: 5px;
    display: flex;
    margin: 15px auto 5px auto;
}
.right-radio {
    margin-left: 20px;
}
.hidden-field {
    height: 0;
    visibility: hidden;
    border: 0;
    padding: 0;
}
.success-msg {
    color: #4F8A10;
    background-color: #DFF2BF;
    padding: 10px;
}
.error-msg {
    color: #D8000C;
    background-color: #FFD2D2;
    padding: 10px;
}
