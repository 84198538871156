@font-face {
  font-family: "KGLifeisMessy";
  src: local("KGLifeisMessy"), url("../../fonts/KGLifeisMessy.ttf");
}
@font-face {
  font-family: "BreakfastNoodles";
  src: local("BreakfastNoodles"), url("../../fonts/BreakfastNoodles.otf");
}
@font-face {
    font-family: "DINNextLTProBoldCondensed";
    src: local("DINNextLTProBoldCondensed"), url("../../fonts/DINNextLTPro-BoldCondensed.ttf");
}
@font-face {
    font-family: "GeorgiaItalic";
    src: local("GeorgiaItalic"), url("https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/fonts/Georgia+Italic.ttf");
}
@font-face {
    font-family: "GeorgiaBoldItalic";
    src: local("GeorgiaBoldItalic"), url("https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/fonts/Georgia+Bold+Italic.ttf");
}

.player-dashboard-v2 {
  .loading {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }
  
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #8b3ef6;
    border-color: #8b3ef6 transparent #8b3ef6 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  #dash-container {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    min-height: 100vh;
    overflow: auto;
    padding: 0 15px;
    position: relative;
    width: 100%;
    .wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;
    }
  }
  
  #post-game {
    background: rgba(11, 11, 11, .8);
    height: 100%;
    left: 0;
    min-height: 100vh;
    overflow: auto;
    padding: 40px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1006;
    @media screen and (max-width: 800px) {
      padding: 20px;
      .post-game-content {
        padding: 20px;
      }
    }
    @media screen and (max-width: 750px) {
      padding: 20px;
      .product {
        width: 50%;
      }
    }
    @media screen and (max-width: 550px) {
      padding: 20px;
      .product {
        width: 100%;
      }
    }
    @media screen and (max-width: 425px) {
      padding: 20px;
      .post-game-content a.shop-amazon {
        & > span {
          max-width: 225px;
        }
      }
    }
  }
  .post-game-container {
    height: 100%;
    position: relative;
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
  }
  .post-game-content {
    padding: 40px;
    position: relative;
    margin: 0 auto;
    max-width: 800px;
    min-height: 100%;
    width: 100%;
    img.post-game-bg {
      width: 100%;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      z-index: -1;
    }
    .products {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .products-bg {
        bottom: 10px;
        left: 0;
        opacity: .55;
        position: absolute;
        top: 20px;
        width: 100%;
        z-index: -1;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .product {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0 20px 40px;
        width: 33.3333%;
        img.tshirt {
          max-height: 130px;
          object-fit: contain;
          width: 100%;
        }
        img.hat {
          max-height: 130px;
          object-fit: contain;
          width: 100%;
        }
        p {
          color: #fff;
          font-family: 'DINNextLTProBoldCondensed';
          font-size: .875rem;
          margin: 10px 0;
        }
        a {
          position: relative;
          width: 180px;
          & > img {
            object-fit: contain;
            width: 100%;
          }
          span {
            color: #60259e;
            font-family: "KGLifeisMessy";
            font-size: .75rem;
            position: absolute;
            left: 50%;
            text-align: center;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
          }
        }
      }
    }
    a.shop-amazon {
      display: block;
      margin: 0 auto;
      position: relative;
      max-width: 350px;
      width: 100%;
      & > img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
      }
      span {
        color: #60259e;
        display: block;
        font-family: "KGLifeisMessy";
        font-size: 1rem;
        margin: 0 auto;
        padding: 18px 20px 16px;
        text-align: center;
        width: 100%;
      }
    }
    .review {
      margin: 20px auto 0;
      position: relative;
      &.pre-rating, &.low-rating {
        max-width: 300px;
      }
      &.five-star-rating {
        height: 116.25px;
        max-width: 400px;
        img.review-box {
          height: 100%;
          object-fit: fill;
        }
        .review-content {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .text-five-star-rating {
            color: #60259e;
            font-family: "KGLifeisMessy";
            font-size: 1.25rem;
            margin-bottom: 8px;
          }
          a {
            color: #60259e;
            font-family: "KGLifeisMessy";
            font-size: .75rem;
            padding: 0 20px;
            text-align: center;
            text-decoration: underline;
            transition: opacity .2s;
            &:hover {
              opacity: .8;
            }
          }
        }
      }
      img.review-box {
        object-fit: contain;
        width: 100%;
      }
      .review-content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        .stars {
          display: flex;
          margin: 5% auto 0;
          position: relative;
          width: 75%;
          .empty, .fill {
            display: flex;
            flex-direction: row-reverse;
            position: absolute;
          }
          img {
            cursor: pointer;
            width: 20%;
          }
          .fill img {
            opacity: 0;
            transition: opacity .2s;
            &:hover, &:hover ~ img {
              opacity: 1;
            }
          }
        }
        .text {
          color: #60259e;
          font-family: "KGLifeisMessy";
          position: absolute;
          left: 50%;
          width: 100%;
          text-align: center;
          transform: translateX(-50%);
          top: 62%;
        }
        .text-low-rating {
          color: #60259e;
          font-family: "KGLifeisMessy";
          position: absolute;
          left: 50%;
          width: 100%;
          text-align: center;
          transform: translate(-50%, -50%);
          top: 50%;
        }
      }
    }
  }
  
  #header {
    display: flex;
    height: 80px;
    justify-content: center;
    padding: 5px 0;
    position: relative;
    width: 100%;
    img {
      height: 100%;
      padding: 10px 0;
      &.zoom {
        position: absolute;
        right: 0;
        padding: 22px 0;
      }
    }
  }
  
  #viewer {
    background-color: #000;
    border: 8px solid;
    display: flex;
    flex-direction: column;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
    .react-viewer-inline {
      min-height: auto;
      position: absolute;
    }
    .react-viewer-mask {
      background-color: transparent;
    }
    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .img-wrapper {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      padding: 20px;
      position: absolute;
      top: 0;
      width: 100%;
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
  
  #assets {
    $assets-padding-top: 15px;
    $assets-padding-bottom: 10px;
    $assetsMinHeight: 190px;
    padding: $assets-padding-top 0 $assets-padding-bottom;
    min-height: $assetsMinHeight + $assets-padding-top + $assets-padding-bottom;
    width: 100%;
    .react-tabs {
      display: flex;
      flex-direction: column;
      height: 100%;
      .tab {
        &-content {
          align-items: center;
          display: flex;
          height: 100%;
          position: relative;
          img {
            height: 100%;
            padding: 8px;
            z-index: 1;
          }
          h2 {
            font-size: 1rem;
            margin: 0;
            z-index: 1;
          }
          span {
            position: absolute;
            right: 25px;
            background: #d61249;
            border-radius: 9999px;
            display: flex;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
            height: 15px;
            text-align: center;
            line-height: .3;
            justify-content: center;
            align-items: center;
            font-size: .75rem;
            color: #fff;
          }
        }
      }
      &__tab-panel {
        flex: 1;
        min-height: 1px;
        .panel {
          height: 100%;
          .prevAsset, .nextAsset {
            transition: all .2s;
          }
          .prevAsset:not(.isDisabled):hover, .nextAsset:not(.isDisabled):hover {
            transform: scale(1.05) translateY(-50%);
          }
          .prevAsset.isDisabled, .nextAsset.isDisabled {
            cursor: default;
            opacity: .25;
          }  
          &.slider {
            display: flex;
            padding: 0 30px;
          }
          &.scroll {
            display: none;
            overflow: auto;
            .simplebar-content {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              img {
                display: block;
              }
            }
          }
          @media screen and (max-width: 600px) {
            &.slider { display: none; }
            &.scroll { display: flex; }
          }
        }
      }
    }
    .scroll .asset, .slider .asset {
      position: relative;
      .asset-wrapper {
        $asset-wrapper-margin: 15px;
        text-align: center;
        cursor: pointer;
        max-height: 100%;
        max-width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: $asset-wrapper-margin;
        left: $asset-wrapper-margin;
        bottom: $asset-wrapper-margin;
        right: $asset-wrapper-margin;
        &:hover .asset-img-wrapper {
          transform: scale(1.05);
        }
        .asset-img-wrapper {
          min-height: 1px;
          padding: 10px;
          align-items: center;
          display: flex;
          justify-content: center;
          transition: transform .2s;
          img {
            max-height: 100%;
            max-width: 100%;
            transition: transform .2s;
          }
        }
        p {
          margin: 0;
          padding: 0 10px 8px;
          text-align: center;
        }
      }
    }
    .slider .asset {
      padding-bottom: 160px;
    }
    .scroll .asset {
      padding-bottom: 33.3333%;
      width: 33.3333%;
      @media screen and (max-width: 525px) {
        padding-bottom: 50%;
        width: 50%;
      }
    }
    .views-asset {
      .asset-wrapper {
        background-color: #fff;
        background-position: center;
        background-size: cover;
        border-radius: 4px;
      }
    }
  }
  
  .zoomDownloadLink.mobile {
    align-items: center;
    font-size: .75rem;
    padding: 8px 0 0;
    img {
      margin-right: 10px;
      width: 20px;
    }
  }
  
  #footer {
    height: 65px;
    padding: 0 0 10px;
    img {height:100%;}
  }
  
  .rugrats {
    #header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 85px;
      .right {
        align-items: center;
        display: flex;
      }
      a.zoomDownloadLink {
        height: 55px;
        margin-right: 20px;
        img.zoom {
          height: auto;
          max-height: 100%;
          max-width: 100%;
          padding: 0;
          position: static;
          width: auto;
        }
      }
    }
    #viewer {
      background-color: #fff;
      border: 8px solid;
      border-image: url('../../images/rugrats-viewer-border.png') 30 25 stretch;
      .rugrats-viewer-border {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
    #assets {
      $panel-top-angle: .75deg;
      $tab-right-angle: 35deg;
      $tab-width: 160px;
      $tab-overlap: 10px;
      $inventory-color: #e2d4f4;
      $locks-color: #c5dffc;
      $views-color:#c8fcd3;
      $founded-color:#f9f9c7;
      $border-color: rgba(144, 87, 181, 1);
      $border-width: 3px;
      $drop-shadow-color: rgba(84, 43, 157, 1);
      $tab-height: 32px;
      padding: 15px 0 15px;
      .views-asset .asset-wrapper {
        color: #fff;
      }
      ul {
        border: 0;
        display: flex;
        height: $tab-height;
        list-style: none;
        margin: 0;
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 20px;
          height: 100%;
          box-shadow: -7px 14px 0px 0px $drop-shadow-color;
        }
        li {
          border: 0;
          border-left: $border-width solid $border-color;
          border-top-left-radius: 25px 255px;
          cursor: pointer;
          height: $tab-height;
          overflow: hidden;
          position: absolute;
          top: $border-width;
          h2 {
            color: $drop-shadow-color;
            font-family: 'BreakfastNoodles';
            font-size: .875rem !important;
            text-transform: uppercase;
          }
          &.react-tabs__tab--selected {
            background: unset;
            border-bottom: 0;
            border-radius: unset;
            border-top-left-radius: 25px 255px;
            z-index: 10;
          }
          &.inventory-tab .shape {
            background-color: $inventory-color;
            border-top: $border-width solid $border-color;
          }
          &.locks-tab .shape {
            background-color: $locks-color;
            border-top: $border-width solid $border-color;
          }
          &.views-tab .shape {
            background-color: $views-color;
            border-top: $border-width solid $border-color;
          }
          &.founded-tab .shape {
            background-color: $founded-color;
            border-top: $border-width solid $border-color;
          }
          @media screen and (max-width: 610px) {
            .tab-content {
              justify-content: center;
              margin-right: 15%;
              span {
                margin-right: 10px;
                position: static;
                transform: unset;
              }
            }
            h2 { display: none; }
          }
          .shape {
            border-right: $border-width solid $border-color;
            border-top-right-radius: 117px 4px;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transform: skewX($tab-right-angle);
            transform-origin: 100% 100%;
            width: 100%;
          }
        }
      }
      .panel {
        border-top: $border-width solid $border-color;
        border-bottom: $border-width solid $border-color;
        border-image: url('../../images/rugrats-panel-border.png') 27 stretch;
        -webkit-box-shadow: -7px 7px 0px 0px $drop-shadow-color;
        -moz-box-shadow: -7px 7px 0px 0px $drop-shadow-color;
        box-shadow: -7px 7px 0px 0px $drop-shadow-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 100%;
        .slick-slider, .simplebar-wrapper {
          z-index: 1;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-right: $border-width solid $border-color;
          border-left: $border-width solid $border-color;
          border-image: url('../../images/rugrats-panel-border-vertical.png') 27 repeat;
        }
        &.inventory-panel {
          background-color: $inventory-color;
        }
        &.locks-panel {
          background-color: $locks-color;
        }
        &.views-panel {
          background-color: $views-color;
        }
        &.founded-panel {
          background-color: $founded-color;
        }
        .slick-track {
          align-items: center;
          display: flex;
        }
        .prevAsset {
          cursor: pointer;
          position: absolute;
          left: -20px;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          img {
            width: 100%;
          }
        }
        .nextAsset {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          img {
            width: 100%;
          }
        }
        h2 {
          margin: 0;
          padding: 20px;
          position: absolute;
          z-index: 1;
        }
      }
      .asset {
        font-family: 'BreakfastNoodles';
        line-height: 1.2;
        p {
          font-size: .875rem;
        }
      }
    }
    .zoomDownloadLink.mobile {
      color: #000;
      font-family: 'BreakfastNoodles';
    }
  }
  
  .mbs {
    background-size: cover !important;
    #header {}
    #viewer {
      background-color: #231f20;
      border: 5px solid;
      border-image: url('https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/viewer-border-image.png') 20 20 stretch;
      outline: 2px solid #231f20;
      .rugrats-viewer-border {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
    #assets {
      $tab-height: 32px;
      .views-asset .asset-wrapper {
        color: #fff;
      }
      ul {
        border: 0;
        display: flex;
        height: $tab-height;
        list-style: none;
        margin: 0;
        position: relative;
        &:before {
          content: "";
          display: block;
          width: 20px;
          height: 100%;
          -webkit-box-shadow: -13px 14px 7px -7px rgba(0,0,0,0.41); 
          box-shadow: 3px 22px 7px 8px rgba(0,0,0,0.41);  
          position: absolute;
        }
        li {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          cursor: pointer;
          height: $tab-height;
          z-index: 1;
          &.inventory-tab {
            background: linear-gradient(#f1e5d8, #dfd6c8);
          }
          &.locks-tab {
            background: linear-gradient(#f09898, #dd8c8c);
          }
          &.views-tab {
            background: linear-gradient(#e9c148, #d7b243);
          }
          .tab-content {
            justify-content: center;
            padding: 0 40px 0 25px;
            img {
              filter: opacity(0.5) drop-shadow(0 0 0 #29513b);
              padding: 8px 8px 8px 0;
            }
          }
          span {
            right: 12px !important;
          }
          h2 {
            color: #29513b;
            font-family: 'GeorgiaItalic';
            font-size: .875rem !important;
          }
          &.react-tabs__tab--selected {
            z-index: 10;
          }
          @media screen and (max-width: 610px) {
            padding: 0;
            width: 33.3333%;
            .tab-content {
              justify-content: center;
              padding: 0;
              img {
                padding: 8px;
              }
              span {
                margin-right: 10px;
                position: static;
                transform: unset;
              }
            }
            h2 { display: none; }
          }
        }
      }
      .panel {
        -webkit-box-shadow: -13px 14px 7px -7px rgba(0,0,0,0.41); 
        box-shadow: -13px 14px 7px -7px rgba(0,0,0,0.41);
        display: flex;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 100%;
        .slick-slider, .simplebar-wrapper {
          z-index: 1;
        }
        &.inventory-panel {
          background-color: #f2e6d9;
        }
        &.locks-panel {
          background-color: #f19999;
        }
        &.views-panel {
          background-color: #eac249;
        }
        .slick-track {
          align-items: center;
          display: flex;
        }
        .prevAsset {
          cursor: pointer;
          position: absolute;
          left: -20px;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          img {
            width: 100%;
          }
        }
        .nextAsset {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          img {
            width: 100%;
          }
        }
        h2 {
          margin: 0;
          padding: 20px;
          position: absolute;
          z-index: 1;
        }
      }
      .asset {
        font-family: 'GeorgiaItalic';
        line-height: 1.2;
        p {
          font-size: .875rem;
        }
      }
    }
    .zoomDownloadLink.mobile {
      color: #000;
      font-family: 'GeorgiaBoldItalic';
      img {
        filter: invert(100%);
      }
    }
  }
}

