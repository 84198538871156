@font-face {
    font-family: "DINNextLTProBoldCondensed";
    src: local("DINNextLTProBoldCondensed"), url("../../fonts/DINNextLTPro-BoldCondensed.ttf");
}
@font-face {
    font-family: "DINNextLTProRegular";
    src: local("DINNextLTProRegular"), url("../../fonts/DINNextLTPro-Regular.ttf");
}
@font-face {
    font-family: "DINNextLTProCondensed";
    src: local("DINNextLTProCondensed"), url("../../fonts/Linotype - DINNextLTPro-Condensed.otf");
}

.player-dashboard-v3 {

  .loading {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }
  
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #8b3ef6;
    border-color: #8b3ef6 transparent #8b3ef6 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
      
  #header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;
    .left {
      display: flex;
      max-width: 200px;
      width: 30%;
      img {
        object-fit: contain;
        width: 100%;
      }
    }
    .center {
      height: 45px;
      max-width: 100px;
      width: 30%;
      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }
    .right {
      align-items: center;
      display: flex;
      & > *:not(:last-child) {
        margin-right: 8px;
      }
      .zoomDownloadLink {
        background: #fff;
        border: 1px solid #000;
        border-radius: 9999px;
        color: #000;
        display: block;
        font-family: 'DINNextLTProBold';
        font-size: 10px;
        height: 36px;
        line-height: 1.1;
        min-width: 117px;
        padding: 5px 12px;
        text-align: center;
        &:hover {
          text-decoration: none;
        }
        img {
          filter: invert(1);
          height: 10px;
          margin-right: 5px;
          vertical-align: baseline;
        }
      }
    }
  }

  
  #dash-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: auto;
    padding: 0 15px;
    position: relative;
    width: 100%;
    .wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;
    }
  }
  
  #post-game {
    background: rgba(11, 11, 11, .8);
    height: 100%;
    left: 0;
    min-height: 100vh;
    overflow: auto;
    padding: 40px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1006;
    @media screen and (max-width: 800px) {
      padding: 20px;
      .post-game-content {
        padding: 20px;
      }
    }
    @media screen and (max-width: 750px) {
      padding: 20px;
      .product {
        width: 50%;
      }
    }
    @media screen and (max-width: 550px) {
      padding: 20px;
      .product {
        width: 100%;
      }
    }
    @media screen and (max-width: 425px) {
      padding: 20px;
      .post-game-content a.shop-amazon {
        & > span {
          max-width: 225px;
        }
      }
    }
  }
  .post-game-container {
    height: 100%;
    position: relative;
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
  }
  .post-game-content {
    padding: 40px;
    position: relative;
    margin: 0 auto;
    max-width: 800px;
    min-height: 100%;
    width: 100%;
    img.post-game-bg {
      width: 100%;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      z-index: -1;
    }
    .products {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .products-bg {
        bottom: 10px;
        left: 0;
        opacity: .55;
        position: absolute;
        top: 20px;
        width: 100%;
        z-index: -1;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .product {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0 20px 40px;
        width: 33.3333%;
        img.tshirt {
          max-height: 130px;
          object-fit: contain;
          width: 100%;
        }
        img.hat {
          max-height: 130px;
          object-fit: contain;
          width: 100%;
        }
        p {
          color: #fff;
          font-family: 'DINNextLTProBoldCondensed';
          font-size: .875rem;
          margin: 10px 0;
        }
        a {
          position: relative;
          width: 180px;
          & > img {
            object-fit: contain;
            width: 100%;
          }
          span {
            color: #60259e;
            font-family: "KGLifeisMessy";
            font-size: .75rem;
            position: absolute;
            left: 50%;
            text-align: center;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
          }
        }
      }
    }
    a.shop-amazon {
      display: block;
      margin: 0 auto;
      position: relative;
      max-width: 350px;
      width: 100%;
      & > img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
      }
      span {
        color: #60259e;
        display: block;
        font-family: "KGLifeisMessy";
        font-size: 1rem;
        margin: 0 auto;
        padding: 18px 20px 16px;
        text-align: center;
        width: 100%;
      }
    }
    .review {
      margin: 20px auto 0;
      position: relative;
      &.pre-rating, &.low-rating {
        max-width: 300px;
      }
      &.five-star-rating {
        height: 116.25px;
        max-width: 400px;
        img.review-box {
          height: 100%;
          object-fit: fill;
        }
        .review-content {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .text-five-star-rating {
            color: #60259e;
            font-family: "KGLifeisMessy";
            font-size: 1.25rem;
            margin-bottom: 8px;
          }
          a {
            color: #60259e;
            font-family: "KGLifeisMessy";
            font-size: .75rem;
            padding: 0 20px;
            text-align: center;
            text-decoration: underline;
            transition: opacity .2s;
            &:hover {
              opacity: .8;
            }
          }
        }
      }
      img.review-box {
        object-fit: contain;
        width: 100%;
      }
      .review-content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        .stars {
          display: flex;
          margin: 5% auto 0;
          position: relative;
          width: 75%;
          .empty, .fill {
            display: flex;
            flex-direction: row-reverse;
            position: absolute;
          }
          img {
            cursor: pointer;
            width: 20%;
          }
          .fill img {
            opacity: 0;
            transition: opacity .2s;
            &:hover, &:hover ~ img {
              opacity: 1;
            }
          }
        }
        .text {
          color: #60259e;
          font-family: "KGLifeisMessy";
          position: absolute;
          left: 50%;
          width: 100%;
          text-align: center;
          transform: translateX(-50%);
          top: 62%;
        }
        .text-low-rating {
          color: #60259e;
          font-family: "KGLifeisMessy";
          position: absolute;
          left: 50%;
          width: 100%;
          text-align: center;
          transform: translate(-50%, -50%);
          top: 50%;
        }
      }
    }
  }
  
  #viewer {
    display: flex;
    flex-direction: column;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
    .react-viewer-inline {
      min-height: auto;
      position: absolute;
    }
    .react-viewer-mask {
      background-color: transparent;
    }
    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .img-wrapper {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 50%;
      max-width: 350px;
      padding: 40px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
  
  #assets {
    $assets-padding-top: 15px;
    $assets-padding-bottom: 10px;
    $assetsMinHeight: 190px;
    padding: $assets-padding-top 0 $assets-padding-bottom;
    min-height: $assetsMinHeight + $assets-padding-top + $assets-padding-bottom;
    width: 100%;
    .react-tabs {
      display: flex;
      flex-direction: column;
      height: 100%;
      &__tab--selected {
        z-index: 10;
      }
      &__tab-list {
        $tab-height: 32px;
        border: 0;
        display: flex;
        height: $tab-height;
        list-style: none;
        margin: 0;
        position: relative;
        &:before {
          content: "";
          display: block;
          width: 20px;
          height: 100%;
          -webkit-box-shadow: -13px 14px 7px -7px rgba(0,0,0,0.41); 
          box-shadow: 3px 22px 7px 8px rgba(0,0,0,0.41);  
          position: absolute;
        }
        .tab {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          cursor: pointer;
          height: $tab-height;
          z-index: 1;
          &-content {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            padding: 0 40px 0 25px;
            position: relative;
            img {
              filter: brightness(0) invert(1) opacity(0.75);
              height: 100%;
              padding: 8px 8px 8px 0;
              z-index: 1;
            }
            h2 {
              color: #fff;
              font-family: 'DINNextLTProRegular';
              font-size: .875rem;
              margin: 0;
              z-index: 1;
            }
            span {
              align-items: center;
              background: #ff505a;
              border-radius: 9999px;
              color: #fff;
              display: flex;
              font-size: .75rem;
              height: 15px;
              justify-content: center;
              line-height: .3;
              position: absolute;
              right: 12px;
              text-align: center;
              top: 50%;
              transform: translateY(-50%);
              width: 18px;
            }
          }
          @media screen and (max-width: 610px) {
            padding: 0;
            width: 33.3333%;
            .tab-content {
              justify-content: center;
              padding: 0;
              img {
                padding: 8px;
              }
              span {
                margin-right: 10px;
                position: static;
                transform: unset;
              }
            }
            h2 { display: none; }
          }
        }
      }
      &__tab-panel {
        flex: 1;
        min-height: 1px;
        .panel {
          -webkit-box-shadow: -13px 14px 7px -7px rgba(0,0,0,0.41); 
          box-shadow: -13px 14px 7px -7px rgba(0,0,0,0.41);
          display: flex;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          border-top-right-radius: 6px;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          overflow: hidden;
          position: relative;
          width: 100%;
          .prevAsset, .nextAsset {
            transition: all .2s;
          }
          .prevAsset {
            cursor: pointer;
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
            img {
              width: 100%;
            }
          }
          .nextAsset {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
            img {
              width: 100%;
            }
          }
          .prevAsset:not(.isDisabled):hover, .nextAsset:not(.isDisabled):hover {
            transform: scale(1.05) translateY(-50%);
          }
          .prevAsset.isDisabled, .nextAsset.isDisabled {
            cursor: default;
            opacity: .25;
          }  
          &.slider {
            display: flex;
            padding: 0 30px;
          }
          &.scroll {
            display: none;
            overflow: auto;
            .simplebar-content {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              img {
                display: block;
              }
            }
          }
          @media screen and (max-width: 600px) {
            &.slider { display: none; }
            &.scroll { display: flex; }
          }
          .slick-list { margin: 0; }
          .slick-slider, .simplebar-wrapper {
            z-index: 1;
          }
          .slick-track {
            align-items: center;
            display: flex;
            margin-top: 0;
          }
          h2 {
            margin: 0;
            padding: 20px;
            position: absolute;
            z-index: 1;
          }
          @media screen and (max-width: 610px) {
            border-top-right-radius: 0;
          }
        }
      }
    }
    .scroll .asset, .slider .asset {
      font-family: 'DINNextLTProRegular';
      line-height: 1.2;
      position: relative;
      .asset-wrapper {
        $asset-wrapper-margin: 15px;
        text-align: center;
        cursor: pointer;
        max-height: 100%;
        max-width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: $asset-wrapper-margin;
        left: $asset-wrapper-margin;
        bottom: $asset-wrapper-margin;
        right: $asset-wrapper-margin;
        &:hover .asset-img-wrapper {
          transform: scale(1.05);
        }
        .asset-img-wrapper {
          min-height: 1px;
          padding: 10px;
          align-items: center;
          display: flex;
          justify-content: center;
          transition: transform .2s;
          img {
            max-height: 100%;
            max-width: 100%;
            transition: transform .2s;
          }
        }
        p {
          font-size: .875rem;
          margin: 0;
          padding: 0 10px 8px;
          text-align: center;
        }
      }
    }
    .slider .asset {
      padding-bottom: 160px;
    }
    .scroll .asset {
      padding-bottom: 33.3333%;
      width: 33.3333%;
      @media screen and (max-width: 525px) {
        padding-bottom: 50%;
        width: 50%;
      }
    }
    .views-asset {
      .asset-wrapper {
        background-color: #fff;
        background-position: center;
        background-size: cover;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
  
  #footer {
    align-items: center;
    background: #fff;
    display: flex;
    height: 65px;
    justify-content: center;
    margin: 0 -15px;
    overflow: hidden;
    padding: 8px 15px;
    img { height: 100%; }
    .spacer {
      border-right: 1px solid #000;
      height: 100%;
      margin: 0 15px;
    }
    p { margin: 0; }
  }

}

