.sidebar {
  width: 100%;
  display: inline-block;
  background-color: #FFFFFF;
  margin-top: 12px;
  margin-left: 5px;
  margin-right: 5px;
  min-height: 100vh !important;
  z-index: 100;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,.125);
}
.paragraph-text {
  margin-bottom: 0px;
  font-size: 12px;
  .edit-icon {
    padding-left: 3px;
    float: right;
    cursor: pointer;
  }
  input {
    display: inline-block;
  }
}
.cursor-pointer {
  cursor: pointer;
}
