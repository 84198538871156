.ruins,
.ruins-forbidden-treasure {
  & .download-link {
    margin-top: 35px;
    margin-bottom: -10px;
    color: #f7c657;
    font-size: 16px;
    line-height: 45px;
  }
  & .image-icon {
    width: 30px;
    margin-right: 10px;
  }
}

.gold-rush {
  & .download-link {
    margin-top: 35px;
    margin-bottom: -10px;
    color: #fdcb00;
    font-size: 16px;
    line-height: 45px;
  }
  & .image-icon {
    width: 30px;
    margin-right: 10px;
  }
}

.the-heist {
  & .download-link {
    margin-top: 35px;
    margin-bottom: -10px;
    color: #02a6ff;
    font-size: 16px;
    line-height: 45px;
  }
  & .image-icon {
    width: 30px;
    margin-right: 10px;
  }
}

.prison-break {
  & .download-link {
    margin-top: 35px;
    margin-bottom: -10px;
    color: #fcf2e3;
    font-size: 20px;
    line-height: 45px;
  }
  & .image-icon {
    width: 30px;
    margin-right: 10px;
  }

  @media only screen and (max-width: 738px) {
    .download-link {
      font-size: 12px;
    }

    .image-icon {
      width: 25px;
    }
  }

  @media only screen and (max-width: 618px) {
    .download-link {
      font-size: 12px;
    }
  }
}


.truth-seekers {
  & .download-link {
    margin-top: 35px;
    margin-bottom: -10px;
    color: #fcf2e3;
    font-size: 20px;
    line-height: 45px;
  }
  & .image-icon {
    width: 30px;
    margin-right: 10px;
  }

  @media only screen and (max-width: 738px) {
    .download-link {
      font-size: 12px;
    }

    .image-icon {
      width: 25px;
    }
  }

  @media only screen and (max-width: 618px) {
    .download-link {
      font-size: 12px;
    }
  }
}

.proctor-and-gamble {
  & .download-link {
    margin-top: 35px;
    margin-bottom: -10px;
    color: #fcf2e3;
    font-size: 20px;
    line-height: 45px;
    font-weight: 700;
  }
  & .image-icon {
    width: 30px;
    margin-right: 10px;
  }

  @media only screen and (max-width: 738px) {
    .download-link {
      font-size: 12px;
    }

    .image-icon {
      width: 25px;
    }
  }

  @media only screen and (max-width: 618px) {
    .download-link {
      font-size: 12px;
    }
  }
}

.depths {
  & .download-link {
    margin-top: 35px;
    margin-bottom: -10px;
    color: #fff;
    font-size: 16px;
    line-height: 45px;
  }
  & .image-icon {
    width: 30px;
    margin-right: 10px;
  }
}
