@font-face {
  font-family: 'Mont-HeavyDEMO';
  src: local('Mont-HeavyDEMO'), url('../../../../fonts/Mont-HeavyDEMO.otf');
}
.prison-break {
  & .player-wrapper {
    padding: 25px;
    // background: url('../../../../images/pb_background.png');
    background-size: 100% 100%;
    width: 100%;
    font-family: 'Mont-HeavyDEMO';
    color: #4A2B2F;
  }
  & .player-logo-section {
    & .logo-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom:10px;
      max-height: 60px;
      & .player-logos-1 {
        max-width: 285px;
        padding: 5px;
        max-height: 55px;
        object-fit: scale-down;
        margin-right: 5px;
      }
      & .player-logos-2 {
        max-width: 120px;
        padding: 5px;
        margin-right: 5px;
      }
      & .player-logos-3 {
        width:155px;
        padding: 5px;
      }
    }
  }
  & .player-logo-section-sm {
    display: none;
    text-align: center;
    vertical-align: middle;
  }
  & .player-logo-section-lg {
    display: block;
  }
  & .aux-image {
    display: none;
  }
  & .my-50 {
    margin: 30px;
  }

  & .no-show {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    .player-logo-section-lg {
      display: none;
    };

    .player-logo-section-sm {
      display: inline;
    }

    .player-logos {
      width:130px;
      height: 100%;
      padding: 10px;
    }

    .player-wrapper {
      padding: 30px;
    }
  }

};
