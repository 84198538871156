.clue-button {
  background-color: #8e74c3;
  border: 5px solid #8d65aa;
  border-radius: 0px;
  height: 60px;
  width: 100%;
  margin: 30px 10px 5px 0px;
  box-sizing: content-box;
  padding: 0px;
}

.clue-box {
  text-align: center;
  background-color: rgba(244, 236, 214, 0.8);
  border: 5px solid #8e74c3;
  height: 130px;
  width: 100%;
  margin: 5px 10px 20px 0px;
  line-height: 130px;
  box-sizing: content-box;
}
