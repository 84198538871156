.form-container {
  width: 100%;
  max-width: 40rem;
  a {
    color: #fff;
    text-decoration: underline;
  }
  .form-control {
    font-size: 0.8rem;
    border-radius: 0.2rem;
    height:40px;
  }
  .col {
    padding: 7px;
  }
  .row { margin: 0; }
  .form-check-label {
    color: white;
    font-size: 0.84rem;
  }
  .form-check-input {
    margin-top: 0.4rem;
    margin-left: -1.25rem;
  }
  .btn-primary {
    color: black;
    background-color: #fff;
    border-color: #fff;
    font-size: 1rem;
    padding: 0.7rem 6rem;
    border-radius: .2rem;
    font-weight: bold;
    &:hover {
      background-color: #e0d8d8;
      border-color: #e0d8d8;
    }
  }
}

@media only screen and (max-width: 991px) {
  .form-container {
    width: 90%;
  }
}


.validation-tooltip {
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #de2424 !important;
  }
}

.bs-tooltip-auto[x-placement^=right] .arrow:before, .bs-tooltip-right .arrow:before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #de2424;
}

.checkboxes {
  background: rgba(0, 0, 0, 0.4);
  margin: 0 7px;
  border-radius: .2rem;
}