.join-box {
    text-align: center;
    width:100%;
    height:100vh;
    padding: 35% 50px 35% 50px;
    color:#8b3ef6;
}

.redemption-form {
    margin-top: 50px;
}

.left-side {
    background-color: #8b3ef6;
    text-align: center;
    padding-top: 15%;
    padding-bottom: 15%;
}

.right-side {
    background-color: #ffffff;
}

.join-button {
    background-color: #8b3ef6;
    border-color: #8b3ef6;
    color: #35f0f9;
}

.remote-logo {
    width: 90%;
}