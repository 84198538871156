.clue-requested-btn {
    align-items: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24);
    bottom: 15px;
    display: none;
    position: fixed;
    right: 15px;
    z-index: 10;
    &.show {
        display: flex;
    }
    &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
    }
    img {
        height: 35px;
        margin-right: 12px;
    }
    .text {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
            font-weight: 700;
            margin: 0;
        }
        span {
            font-size: 12px;
        }
    }
}

.inventory{
    margin-bottom: 2rem;
    padding: 1rem .5rem 2rem .5rem;
}

.inventory.lock-inventory {
    background-color: #eee;

    .guide-inventory-item-image {
        height: 100px;
        width: 100px;
    }

    .guide-inventory-item-button {
        p {
            margin-bottom: 0;
        }
    }
}

.item-sent {
    background-color: grey !important;
}

.password-popover {
    min-width: 350px;
    .info-heading {
        font-weight: 600;
        margin-bottom: 5px;
    }
    .form-control {
        padding: 0.2rem 0.5rem;
        font-size: 0.7rem;
    }
    .form-control:disabled, .form-control[readonly] {
        background-color: #fdfdfd;
        opacity: 1;
    }
    .btn {
        padding: 0.2rem 0.5rem;
        font-size: 0.7rem;
    }
    .col-md-8 {
        padding-right: 0;
    }
    .copy-btn {
        font-size: 0.7rem;
        width: 100%;
        padding: 0.4rem 0.1rem;
    }
}

.game-info-btn {
    height: 32px;
    border-radius: 50%;
    width: 32px;
    line-height: 4px;
    font-weight: 700;
}

.card-footer svg {
    max-width: 16px;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .card {
        max-width: 23%!important;
    }
}

@media only screen and (max-width: 675px) {
    .card {
        max-width: 31.3333%!important;
    }
    .card-footer svg {
        max-width: 12px;
    }
}
.left-mar {
    margin-left: 10px;
}

#sidebar-wrapper{
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
    transition: all .4s ease;
}