.ruins,
.ruins-forbidden-treasure {
  width: 100%;
  & .player-iframe-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    border: solid 8px #fff;
    margin: 0px;
  }
  & .player-iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  & .player-media-button-container {
    background-color: #fff;
  }
  & .player-video-button {
    border-radius: 0px;
    margin: 10px;
    background-color: #1a431a;
    color: #f7c657;
    border: none;
    & :hover {
      color: #f7c657;
      background-color: #1a431a;
    }
  }
  & .player-scan-button {
    border-radius: 0px;
    margin: 10px;
    background-color: #a37bba;
    color: #000000;
    border: none;
    & :hover {
      color: #a37bba;
      background-color: #000000;
    }
  }
}

.gold-rush {
  width: 100%;
  & .player-iframe-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    border: solid 8px #fdcb00;
    margin: 0px;
  }
  & .player-iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  & .player-media-button-container {
    background-color: #fdcb00;
  }
  & .player-video-button {
    border-radius: 0px;
    margin: 10px;
    background-color: #FFFFFF;
    color: #062b1d;
    border: none;
    & :hover {
      color: #FFF;
      background-color: #062b1d;
    }
  }
  & .player-scan-button {
    border-radius: 0px;
    margin: 10px;
    background-color: #062b1d;
    color: #fff;
    border: none;
    & :hover {
      color: #062b1d;
      background-color: #fff;
    }
  }
}

.the-heist {
  width: 100%;
  & .player-iframe-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    border: solid 8px #e4e4e4;
    margin: 0px;
  }
  & .player-iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  & .player-media-button-container {
    background-color: #e4e4e4;
  }
  & .player-video-button {
    border-radius: 0px;
    margin: 10px;
    background-color: #FFFFFF;
    color: #102044;
    border: none;
    & :hover {
      color: #102044;
      background-color: #FFFFFF;
    }
  }
  & .player-scan-button {
    border-radius: 0px;
    margin: 10px;
    background-color: #02a6ff;
    color: #102044;
    border: none;
    & :hover {
      color: #02a6ff;
      background-color: #102044;
    }
  }
}
.prison-break {
  width: 100%;
  & .player-iframe-container {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height: 0;
    border: solid 8px #fcf2e3;
    margin: 0px;
  }
  & .player-iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
  & .player-media-button-container {
    background-color: #FDF2E4;
  }
  & .player-video-button {
    border-radius: 5px;
    margin: 10px;
    background-color: #538D3F;
    color: white;
    border: none;
    & :hover {
      color: #538D3F;
      background-color: #FFFFFF;
    }
  }
  & .player-scan-button {
    border-radius: 5px;
    margin: 10px;
    background-color: #538D3F;
    color: white;
    border: none;
    & :hover {
      color: white;
      background-color: #538D3F;
    }
  }

}
.truth-seekers {
  width: 100%;
  & .player-iframe-container {
    overflow:hidden;
    padding-bottom:46.25%;
    position:relative;
    height: 0;
    border: solid 8px #fcf2e3;
    margin: 0px;
    background-color: #18222B;
  }
  & .player-iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
  & .player-media-button-container {
    background-color: #FDF2E4;
  }
  & .player-video-button {
    border-radius: 5px;
    margin: 10px;
    background-color: #268399;
    color: white;
    border: none;
    font-weight: 900;
    & :hover {
      color: #268399;
      background-color: #FFFFFF;
    }
  }
  & .player-scan-button {
    border-radius: 5px;
    margin: 10px;
    background-color: #268399;
    color: white;
    border: none;
    font-weight: 900;
    & :hover {
      color: white;
      background-color: #268399;
    }
  }

}

.proctor-and-gamble {
  width: 100%;
  & .player-iframe-container {
    overflow:hidden;
    padding-bottom:46.25%;
    position:relative;
    height: 0;
    border: solid 8px #B01686;
    margin: 0px;
    background-color: #18222B;
  }
  & .player-iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
  & .player-media-button-container {
    background-color:#B01686;
  }
  & .player-video-button {
    border-radius: 5px;
    margin: 10px;
    background-color: #013DA7;
    color: white;
    border: none;
    font-weight: 900;
    & :hover {
      color:#013DA7;
      background-color: #FFFFFF;
    }
  }
  & .player-scan-button {
    border-radius: 5px;
    margin: 10px;
    background-color: #E9761F;
    color: white;
    border: none;
    font-weight: 900;
    & :hover {
      color: white;
      background-color: #E9761F;
    }
  }

}

.depths {
  $depths-green: #062b1d;
  $depths-teal1: #b2e0de;
  $depths-teal2: #00e5df;
  $depths-teal3: #09c9ce;
  $depths-teal4: #139ba4;
  $depths-orange: #d68628;
  $depths-white: #fff;
  width: 100%;
  & .player-iframe-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    border: solid 8px $depths-green;
    margin: 0px;
  }
  & .player-iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  & .player-media-button-container {
    background-color: $depths-green;
  }
  & .player-video-button {
    border-radius: 0px;
    margin: 10px;
    background-color: $depths-orange;
    color: $depths-white;
    border: none;
    transition: all .2s;
    &:hover {
      background-color: darken($depths-orange, 10%);
    }
  }
  & .player-scan-button {
    border-radius: 0px;
    margin: 10px;
    background-color: $depths-white;
    color: $depths-green;
    border: none;
    &:hover {
      background-color: darken($depths-white, 10%);
    }
  }
}
