@font-face {
    font-family: "DINNextLTProBold";
    src: local("DINNextLTProBold"), url("../../fonts/DINNextLTPro-Bold.ttf");
}
@font-face {
    font-family: "DINNextLTProBoldCondensed";
    src: local("DINNextLTProBoldCondensed"), url("../../fonts/DINNextLTPro-BoldCondensed.ttf");
}
@font-face {
    font-family: "DINNextLTProRegular";
    src: local("DINNextLTProRegular"), url("../../fonts/DINNextLTPro-Regular.ttf");
}
  

#testPageWrapper {
    height: 100vh;
    width: 100%;
    header {
        background: #fff;
        .top {
            height: 30px;
            background: #373737;
        }
        .bottom {
            display: flex;
            justify-content: center;
            padding: 0 40px;
            a {
                margin: 10px 0;
                max-width: 300px;
                img {
                    max-width: 100%;
                }
            }
        }
    }
    .banner {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 40px 20px;
        position: relative;
        &__bg {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
        }
        &__content {
            align-items: center;
            display: flex;
            flex-direction: column;
            z-index: 2;
            img {
                max-width: 200px;
                width: 100%;
            }
            h1 {
                color: #fff;
                font-family: DINNextLTProBold;
                font-size: 24px;
                text-align: center;
                text-transform: uppercase;
                margin: 16px 0 0;
            }
        }
    }
    img.locky {
        max-width: 75px;
        display: block;
        margin: 30px auto 0;
    }
    h2.loading_header {
        color: #000;
        font-family: DINNextLTProBoldCondensed;
        font-size: 45px;
        margin: 48px 0 0;
        padding: 0 15px;
        text-align: center;
    }
    h2.success_header {
        color: #6bda77;
        font-family: DINNextLTProBoldCondensed;
        font-size: 45px;
        margin: 12px 0 0;
        padding: 0 15px;
        text-align: center;
    }
    p.success_text {
        font-family: DINNextLTProRegular;
        font-size: 19.2px;
        margin: 24px auto 0;
        padding: 0 15px;
        text-align: center;
        max-width: 625px;
    }
    .results {
        display: flex;
        width: 100%;
        max-width: 750px;
        justify-content: space-between;
        margin: 25px auto;
        padding: 0 15px;
        &__left {
            list-style: none;
            border: 1px solid #000;
            padding: 0;
            border-radius: 4px;
            margin: 0 25px 0 0;
            height: 100%;
            li {
                display: flex;
                align-items: center;
                padding: 8px 12px;
                &:not(:last-child) {
                    border-bottom: 1px solid #000;
                }
                img {
                    margin-right: 10px;
                    width: 20px;
                }
                p {
                    margin: 0;
                }
            }
        }
        &__right {
            padding: 20px;
            flex: 1;
            background: #eae8e8;
            border-radius: 4px;
            p {
                margin-bottom: 12px;
            }
            span {
                text-decoration: underline;
                margin-bottom: 4px;
                display: block;
            }
        }
        @media only screen and (max-width: 730px) {
            flex-direction: column;
            max-width: 500px;
            ul {
                margin: 0 0 30px;
            }
        }
    }
    .copy-btn-wrapper {
        margin: 40px 0 0;
        padding: 0 0 80px;
        position: relative;
    }
    .copy-btn {
        font-family: DINNextLTProBold;
        border-radius: 100px;
        border: 0;
        padding: 14px 0 12px;
        width: 300px;
        font-size: .9375rem;
        text-align: center;
        position: relative;
        overflow: hidden;
        transition: all .5s ease;
        vertical-align: middle;
        text-transform: uppercase;
        color: #fff;
        background: #000000;
        cursor: pointer;
        margin: 0 auto;
        transition: all .2s;
        &:hover {
            background: #ca2127;
        }
    }

}

.lds-ellipsis {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #00000045;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }