.content {
  height: calc(100vh - 60px);
  overflow-y: scroll;
  background-color: #F8F9FA;

  .partial-view {
    width: calc(100vw - 300px);
    display: inline-block;
  }

  .module-name {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .drawer-wrapper {
    width: 300px;
    display: inline-block;
    padding: 10px;
  }

  .flex-content {
    display: flex;
    justify-content: space-between;
  }

  .calender-icon {
    margin-right: 10px;
    vertical-align: text-top;
  }

  .react-datepicker__input-container {
    input {
      display: block;
      width: 100%;
      height: calc(1.5em + .75rem + 2px);
      padding: 10px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
  }
}

.react-datepicker {
  width: max-content;
}

.stats-box {
  height: 131px;
  text-align: center;
}

.appointments-list {
  background-color: #FFFFFF;
  margin-top: 10px;
  padding: 20px;
  table-layout: fixed;
  text-align: center;
}

.paragraph-text {
  margin-bottom: 0px;
  font-size: 12px;
}
.error-box {
  border: solid 1px lightcoral;
  border-radius: 5px;
}
.cursor-pointer {
  cursor: pointer;
}

.toast {
  position: absolute;
  bottom: 0px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding-right: 4rem;
  font-size: 1rem;
  button:focus {
    outline: none;
  }
}
