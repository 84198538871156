@font-face {
  font-family: "Linotype";
  src: local("Heavitas"), url("../../../fonts/Linotype - DINNextLTPro-Bold.otf");
}

.player-auth-container {
  background: url("../../../images/RemoteAdventure_EmailSignUp_Background.png");
  background-size: cover;
  min-height: 100vh;
  @media only screen and (max-width: 738px) {
    background: url("../../../images/RemoteAdventure_EmailSignUp_Mobile_Background.png");
    background-size: cover;
  }
  .player-auth {
    padding-top: 3rem;
    .logo-container {
      text-align: center;
      img {
        width: 14rem;
      }
    }
    .form-container {
      width: 40rem;
      margin: auto;
      margin-top: 3.5rem;
      font-family: "Linotype";
      .form-control {
        padding: 1.5rem 0.8rem;
        font-size: 0.8rem;
        border-radius: 0.2rem;
        height: calc(2.5em + 0.75rem + 2px);
      }
      .col {
        padding: 7px;
      }
      .form-check-label {
        color: white;
        font-size: 0.84rem;
      }
      .form-check-input {
        margin-top: 0.4rem;
        margin-left: -1.25rem;
      }
      .btn-primary {
        color: red;
        background-color: #fff;
        border-color: #fff;
        font-size: 0.8rem;
        padding: 0.7rem 4rem;
        border-radius: 2rem;
        &:hover {
          color: red;
          background-color: #e0d8d8;
          border-color: #e0d8d8;
        }
      }
    }

    @media only screen and (max-width: 991px) {
      .form-container {
        width: 90%;
      }
    }
  }
  &.rugrats {
    #dino {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 300px;
      @media only screen and (max-width: 1150px) {
        width: 200px;
      }
      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }
    .logo-container img {
      max-width: 475px;
      padding: 0 20px;
      width: 100%;
    }
    .form-control {
      border-color: transparent;
      &:focus {
        border-color: #542b9d;
        box-shadow: 0 0 0 0.2rem rgba(84, 43, 157, .5);
      }
    }
    label {
      color: #000 !important;
      a {
        color: #542b9d !important;
      }
    }
    [type="button"].btn.btn-primary {
      background-color: #542b9d;
      border-color: #542b9d;
      color: #fff;
    }
  }
  &.mbs {
    .logo-container img {
      max-width: 300px;
      width: 100%;
    }
    .text-danger {
      color: #efbb2a !important;
    }
    .form-container button.btn {
      background: #efbb2a;
      border: 0;
      color: #fff;
      transition: all .2s;
      &:hover {
        background: darken(#efbb2a, 10%);
        color: #fff;
      }
    }
  }
}

.validation-tooltip {
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #de2424 !important;
  }
}

.bs-tooltip-auto[x-placement^=right] .arrow:before, .bs-tooltip-right .arrow:before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #de2424;
}