@font-face {
  font-family: "Corduroy-Regular";
  src: local("Corduroy-Regular"), url("../../../../fonts/Corduroy-Regular.otf");
}
.gold-rush {
  & .player-wrapper {
    padding: 25px;
    // background: url("../../../../images/gr_background.png");
    background-size: 100% 100%;
    margin: 0px;
    width: 100%;
    font-family: "Corduroy-Regular";
    color: #062b1d;
  }
  & .player-logo-section-sm {
    display: none;
    text-align: center;
    vertical-align: middle;
  }
  & .player-logo-section-lg {
    display: block;
  }
  & .player-logo-section {
    & .logo-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom:10px;
      max-height: 60px;
      & .player-logos-1 {
        max-width: 285px;
        padding: 5px;
        height: 50px;
        margin-right: 5px;
        object-fit: scale-down;
      }
      & .player-logos-2 {
        max-width: 200px;
        padding: 5px;
        margin-right: 5px;
        object-fit: scale-down;
      }
      & .player-logos-3 {
        width:155px;
        padding: 5px;
      }
    }
  }
  & .aux-image {
    display: none;
  }
  & .my-50 {
    margin-top: 50px;
  }

  & .no-show {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    .player-logo-section-lg {
      display: none;
    }

    .player-logo-section-sm {
      display: inline;
    }
    .player-logos {
      width:130px;
      height: 100%;
      padding: 10px;
    }
  }
}
