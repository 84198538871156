@font-face {
  font-family: 'Mont-HeavyDEMO';
  src: local('Mont-HeavyDEMO'), url('../../../../fonts/Mont-HeavyDEMO.otf');
}
.depths {
  & .player-wrapper {
    padding: 0 25px 25px 25px;
    background-size: 100% 100%;
    margin: 0px;
    width: 100%;
    font-family: 'Mont-HeavyDEMO';
    color: #062b1d;
  }
  & .player-logo-section-sm {
    display: none;
    text-align: center;
    vertical-align: middle;
  }
  & .player-logo-section-lg {
    display: block;
  }
  & .player-logo-section {
    & .logo-container {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 60px;
      align-items: center;
      & .player-logos-1 {
        max-width: 350px;
        margin-right: 8px;
      }
      & .player-logos-2 {
        max-height: 100%;
        padding: 8px;
      }
      & .player-logos-3 {
        width:155px;
        padding: 5px;
      }
    }
  }
  & .aux-image {
    display: none;
  }
  & .my-50 {
    margin-top: 50px;
  }

  & .no-show {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    .player-logo-section-lg {
      display: none;
    }

    .player-logo-section-sm {
      display: inline;
    }
    .player-logos {
      width:130px;
      height: 100%;
      padding: 10px;
    }
  }
}
