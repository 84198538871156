@font-face {
  font-family: "Heavitas";
  src: local("Heavitas"), url("../../../../fonts/Heavitas.ttf");
}

.ruins,
.ruins-forbidden-treasure {
  & .player-wrapper {
    padding: 25px;
    // background: url("../../../../images/ruins_background.jpg");
    background-size: 100% 100%;
    margin: 0px;
    width: 100%;
    font-family: "Heavitas";
    color: #1a431a;
  }
  & .player-logo-section {
    & .logo-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom:10px;
      & .player-logos-1 {
        max-width: 285px;
        padding: 5px;
        height: 55px;
        margin-right: 5px;
        object-fit: scale-down;
      }
      & .player-logos-2 {
        max-width: 140px;
        padding: 5px;
        margin-right: 5px;
        object-fit: scale-down;
        height: 70px;
        margin-top: -5px;
      }
    }
  }
  & .player-logo-section-sm {
    display: none;
    text-align: center;
    vertical-align: middle;
  }
  & .player-logo-section-lg {
    display: block;
  }
  & .aux-image {
    width: 200px;
    height:200px;
    bottom: 0px;
    margin-left: auto;
    display: block;
    top: -15px;
    right: -11px;
  }
  & .my-50 {
    margin-top: 50px;
  }

  & .no-show {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    .player-logo-section-lg {
      display: none;
    }

    .player-logo-section-sm {
      display: inline;
    }

    .player-logos {
      width:130px;
      height: 100%;
      padding: 10px;
    }

  }
}
